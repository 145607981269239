root {
	height: 100%;
}

* {
	font-family: 'Poppins', Arial, sans-serif;
	box-sizing: border-box;
}

:root {
	/*
   * Colors
   * ======================================================================== */
	--color-primary: #3cd070;
	--color-primary-light: #78ffa0;
	--color-primary-dark: #009e43;
	--color-secondary: #ff6f00;
	--color-secondary-light: #ffa040;
	--color-secondary-dark: #c43e00;
	--color-gray: #dadadc;
	--color-darkgray: #a8a8aa;
	--color-light: #efeeed;
	--color-dark: #535353;
	--color-red: #ff2323;
	/*
   * Layout
   * ======================================================================== */
	--max-content-width: 1000px;
	/*
   * Media queries breakpoints
   * ======================================================================== */
	--screen-xs-min: 480px;
	/* Extra small screen / phone */
	--screen-sm-min: 768px;
	/* Small screen / tablet */
	--screen-md-min: 992px;
	/* Medium screen / desktop */
	--screen-lg-min: 1200px;
	/* Large screen / wide desktop */
}

input[type='file'] {
	display: none;
}

button {
	border: none;
	background: none;
}

button:disabled,
a.buttonLink:disabled,
label.buttonLink-disabled {
	cursor: not-allowed;
}

input,
textarea {
	display: block;
	width: 100%;
	padding: 0.75rem 0.5rem;
	border: 1px solid var(--color-darkgray);
	color: var(--color-dark);
	font-size: 14px;
	transition: all 0.1s ease-in-out;
}

/* This may also activate `border` in react-select */
input:focus,
textarea:focus {
	/* border: 1px solid var(--color-dark); */
}

textarea {
	resize: none;
}

input:focus,
textarea:focus {
	outline: none;
}

input:disabled,
textarea:disabled {
	cursor: not-allowed;
	opacity: 0.7;
	background: rgba(0, 0, 0, 0.05);
}

input.form-input-error,
textarea.form-input-error {
	border: 1px solid #f36 !important;
}

.page-root {
	min-height: 100%;
	padding-top: 2rem;
}

.page-header {
	padding: 0.5rem 0rem;
}

.page-heading {
	font-weight: 300;
}

.page-logo {
	height: 100px;
	margin-bottom: 5rem;
}

.altpage-root {
	background-color: var(--color-primary);
	min-height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.altpage-header {
	display: flex;
	flex-direction: row;
	margin-bottom: 2rem;
	width: 30%;
	justify-content: space-between;
}

.altpage-header img {
	margin-bottom: 0.5rem;
}

.altpage-header span {
	color: white;
}

.altpage-footer span {
	font-size: 0.75rem;
	color: white;
}

.loadingContainer {
	padding-top: 35vh;
}

.success-message {
	color: var(--color-primary);
	font-size: 14px;
}

.error-message {
	color: var(--color-secondary);
	font-size: 14px;
}

/* shake animation */

@-webkit-keyframes shake {
	from,
	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	10%,
	30%,
	50%,
	70%,
	90% {
		-webkit-transform: translate3d(-10px, 0, 0);
		transform: translate3d(-10px, 0, 0);
	}

	20%,
	40%,
	60%,
	80% {
		-webkit-transform: translate3d(10px, 0, 0);
		transform: translate3d(10px, 0, 0);
	}
}

@keyframes shake {
	from,
	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	10%,
	30%,
	50%,
	70%,
	90% {
		-webkit-transform: translate3d(-10px, 0, 0);
		transform: translate3d(-10px, 0, 0);
	}

	20%,
	40%,
	60%,
	80% {
		-webkit-transform: translate3d(10px, 0, 0);
		transform: translate3d(10px, 0, 0);
	}
}

.error-shake {
	-webkit-animation-name: shake;
	animation-name: shake;
	-webkit-animation-duration: 0.8s;
	animation-duration: 0.8s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

#error-border-id {
	border: 1px solid #ff6f00;
}

#error-shake-id {
	-webkit-animation-name: shake;
	animation-name: shake;
	-webkit-animation-duration: 0.8s;
	animation-duration: 0.8s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	border: 1px solid #ff6f00;
}

@media all and (max-width: 1024px) {
	.altpage-footer {
		padding: 0 0 2em 0;
	}
}

@media all and (max-width: 600px) {
	.altpage-header {
		padding: 2em 0 0 0;
		width: 90%;
	}
}
