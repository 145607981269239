.LoadingSpinner-root {
  margin: 0 auto;
  border: 5px solid var(--color-light);
  border-top: 5px solid var(--color-primary);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 0.7s linear infinite;
}

.LoadingSpinner-root-small {
  margin: 0 auto;
  border: 2.5px solid var(--color-light);
  border-top: 2.5px solid var(--color-primary);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 0.7s linear infinite;
}


.LoadingSpinner-root-xs {
  margin: 0 auto;
  border: 2.5px solid var(--color-light);
  border-top: 2.5px solid var(--color-primary);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 0.7s linear infinite;
}

.LoadingSpinner-root-medium {
  margin: 0 auto;
  border: 2.5px solid var(--color-light);
  border-top: 2.5px solid var(--color-primary);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 0.7s linear infinite;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
