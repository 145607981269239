.react-select-custom-option {
	transition: background 60ms;
}
.react-select-custom-option:hover {
	transition-delay: 60ms;
	background: #deebff;
}
.react-select-custom-option.react-select-custom-select__option--is-focused {
	background: none;
}
.react-select-custom-option.react-select-custom-select__option--is-selected {
	background: #bebbf3;
}
