.LoadingDots {
  margin: 0 auto;
  width: 100px;
  text-align: center;
}

.LoadingDots > div {
  width: 20px;
  height: 20px;
  background: var(--color-primary);

  margin: 0 4px;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.LoadingDots .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.LoadingDots .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}